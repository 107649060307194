@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

p, li, label {
    @apply text-sm font-light;
}

.square-bullet-list li {
    @apply flex;
}

.link {
    @apply text-blue-500 underline
}

.square-bullet-list li::before {
    @apply mt-1 mr-4 bg-primary-dark min-w-[10px] min-h-[10px] w-[10px] h-[10px] block;

    content: '';
}

@font-face {
    font-family: 'Mabry';
    src: local('Mabry-Bold-Pro'), url(../public/assets/fonts/Mabry-Bold-Pro.woff2) format('woff2'), url(../public/assets/fonts/Mabry-Bold-Pro.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mabry';
    src: local('Mabry-Regular-Pro'), url(../public/assets/fonts/Mabry-Regular-Pro.woff2) format('woff2'), url(../public/assets/fonts/Mabry-Regular-Pro.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mabry';
    src: local('Mabry-Italic-Pro'), url(../public/assets/fonts/Mabry-Italic-Pro.woff2) format('woff2'), url(../public/assets/fonts/Mabry-Italic-Pro.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'BL Pylon';
    src: local('BLPylonBold-BLPylonBold'), url(../public/assets/fonts/BLPylonBold-BLPylonBold.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BL Pylon';
    src: local('BLPylonBold-BLPylonBookItalic'), url(../public/assets/fonts/BLPylonBold-BLPylonBookItalic.otf) format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'BL Pylon';
    src: local('BLPylonBook-BLPylonBook'), url(../public/assets/fonts/BLPylonBook-BLPylonBook.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BL Pylon';
    src: local('BLPylonBookItalic-BLPylonBookItalic'), url(../public/assets/fonts/BLPylonBookItalic-BLPylonBookItalic.otf) format('opentype');
    font-weight: 400;
    font-style: italic;
}

.popup-overlay {
    @apply fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-50 z-[9999] flex justify-center items-center;
}

.popup-container {
    @apply relative bg-[#FFFFFF] flex flex-col gap-3 px-[70px] py-[50px];
    animation: popup-bounce-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes popup-bounce-in {
    0% {
        transform: translateY(100%);
    }
    60% {
        transform: translateY(-30%);
    }
    80% {
        transform: translateY(10%);
    }
    100% {
        transform: translateY(0%);
    }
}