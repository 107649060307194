#toolbar, .a9s-toolbar, .tag-selector, .tag-selector ul, .tag-selector li {
    display:flex;
  }

.a9s-toolbar-btn.active g.handles circle {
  stroke: black !important;
  fill: rgba(0,0,0,0) !important;
}

.a9s-toolbar-btn:hover {
  background-color: rgba(255,0,0,0) !important;
}

.a9s-toolbar-btn.active {
  background-color: rgba(0,0,0,0.0) !important;
}

.a9s-toolbar-btn.active:hover {
  background-color: rgba(255,0,0,0) !important;
}

.openseadragon-canvas {
  background-color: #efefef !important;
}

  .toolbar-wrapper {
    display: flex;
    flex-direction: row;
    justify-items: center;
    padding-bottom:10px;
  }
  
  .toolbar-wrapper ul {
    padding:0;
    margin:0;
  }
  
  .toolbar-wrapper .tag-selector button {
    outline:none;
    border:none;
    height:45px;
    vertical-align:middle;
    margin-right:8px;
    padding:0 14px;
    border-radius:4px;
    cursor:pointer;
    font-weight:bold;
    color:#838282;
  }
  
  .toolbar-wrapper .tag-selector .yellow.active button {
    color: black;
    background-color:yellow;
  }

  .a9s-annotationlayer {
    width: 400px;
    height: 600px;
  }
  
  .a9s-annotationlayer .a9s-annotation .a9s-outer, 
  .a9s-annotationlayer .a9s-selection .a9s-outer {
    stroke-width:1px;
    stroke:#000;  
  }

  .a9s-inner::after {
    fill:#838282;
  }

  /*Na het selecteren*/
  .a9s-annotationlayer .a9s-annotation .a9s-inner,
  .a9s-annotationlayer .a9s-selection .a9s-inner {
    stroke-width:2px;
    stroke:white;
  }

  .a9s-selection.improved-polygon .a9s-rubberband {
    fill: rgba(255,255,255,0) !important;
    stroke-width: 1px !important;
    stroke: rgba(0, 0, 0, 0) !important;
}

.a9s-selection.improved-polygon .a9s-inner {
    stroke-width:2px !important;
    stroke:#fff !important;
    stroke-dasharray: none !important;
}

.a9s-selection.improved-polygon .a9s-outer {
  stroke-width: 2px !important;
  stroke:#000 !important;
  stroke-dasharray: none !important;
}
.a9s-annotation.improved-polygon.editable .a9s-inner {
  stroke-dasharray: none !important;
}

.a9s-selection.improved-polygon {
  stroke-dasharray: none !important;
}

.a9s-annotationlayer circle.a9s-handle-inner {
  display:none;
}

.a9s-crosshair line {
  stroke-width: 2px !important;
  stroke: yellow !important;
  padding: 5px !important;
}

/*.a9s-annotation.editable.selected {*/
/*  fill: rgba(0, 0, 0, 0.5) !important;*/
/*}*/

/*.a9s-annotation.editable .a9s-inner {*/
/*  fill: rgba(0, 0, 0, 0.5) !important;*/
/*}*/

/*COLOUR SELECTION*/

/*Green colour*/
.toolbar-wrapper .tag-selector .green.active button {
  color: #1EF604;
  background-color: #1EF604;
}

.a9s-annotationlayer .a9s-annotation.GREEN .a9s-inner {
  stroke: #1EF604 !important;
}

.a9s-annotationlayer .a9s-annotation.GREEN.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.GREEN:hover .a9s-inner  {
  stroke:#1EF604 !important;
}

.a9s-annotationlayer .a9s-annotation .GREEN .a9s-inner,
.a9s-annotationlayer .a9s-selection .GREEN .a9s-inner {
  stroke-width:2px !important;
  stroke:#1EF604 !important;
}

.a9s-annotation.editable.selected .GREEN {
  fill: rgba(30,246,4, 0.15);
}

.a9s-annotation.editable .GREEN .a9s-inner {
  fill: rgba(30,246,4, 0.15);
}

.improved-polygon .selected .editable .GREEN {
  fill: rgba(30,246,4, 0.15) !important;
}

.a9s-annotation.editable.GREEN .a9s-inner:hover {
  fill: rgba(30,246,4, 0.07) !important;
}

.a9s-annotation.editable .GREEN .a9s-inner:hover {
  fill: rgba(30,246,4, 0.07) !important;
}

.a9s-annotation.editable.GREEN .a9s-inner {
  fill: rgba(30,246,4, 0.15);
}

/*Yellow colour*/
.toolbar-wrapper .tag-selector .yellow.active button {
  color:#FFC800;
  background-color: #FFC800;
}

.a9s-annotationlayer .a9s-annotation.YELLOW .a9s-inner {
  stroke: #FFC800 !important;
}

.a9s-annotationlayer .a9s-annotation.YELLOW.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.YELLOW:hover .a9s-inner  {
  stroke:#FFC800 !important;
}

.a9s-annotationlayer .a9s-annotation .YELLOW .a9s-inner,
.a9s-annotationlayer .a9s-selection .YELLOW .a9s-inner {
  stroke-width:2px !important;
  stroke:#FFC800 !important;
}

.a9s-annotation.editable.selected .YELLOW {
  fill: rgba(255,200,0, 0.15) !important;
}

.a9s-annotation.editable .YELLOW .a9s-inner {
  fill: rgba(255,200,0, 0.15) !important;
}

.a9s-annotation.editable.YELLOW .a9s-inner:hover {
  fill: rgba(255,200,0, 0.07) !important;
}

.a9s-annotation.editable .YELLOW .a9s-inner:hover {
  fill: rgba(255,200,0, 0.07) !important;
}

.a9s-annotation.editable.YELLOW .a9s-inner {
  fill: rgba(255,200,0, 0.15) !important;
}

/*Orange colour*/
.toolbar-wrapper .tag-selector .orange.active button {
  color:#FF8B4A;
  background-color: #FF8B4A;
}

.a9s-annotationlayer .a9s-annotation.ORANGE .a9s-inner {
  stroke: #FF8B4A !important;
}

.a9s-annotationlayer .a9s-annotation.ORANGE.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.ORANGE:hover .a9s-inner  {
  stroke:#FF8B4A !important;
}

.a9s-annotationlayer .a9s-annotation .ORANGE .a9s-inner,
.a9s-annotationlayer .a9s-selection .ORANGE .a9s-inner {
  stroke-width:2px !important;
  stroke:#FF8B4A !important;
}

.a9s-annotation.editable.selected .ORANGE {
  fill: rgba(255,139,74, 0.15) !important;
}

.a9s-annotation.editable .ORANGE .a9s-inner {
  fill: rgba(255,139,74, 0.15) !important;
}

.a9s-annotation.editable.ORANGE .a9s-inner:hover {
  fill: rgba(255,139,74, 0.07) !important;
}

.a9s-annotation.editable .ORANGE .a9s-inner:hover {
  fill: rgba(255,139,74, 0.07) !important;
}

.a9s-annotation.editable.ORANGE .a9s-inner {
  fill: rgba(255,139,74, 0.15) !important;
}

/*Blue colour*/
.toolbar-wrapper .tag-selector .blue.active button {
  color:#00E0FF;
  background-color: #00E0FF;
}

.a9s-annotationlayer .a9s-annotation.BLUE .a9s-inner {
  stroke: #00E0FF !important;
}

.a9s-annotationlayer .a9s-annotation.BLUE.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.BLUE:hover .a9s-inner  {
  stroke:#00E0FF !important;
}

.a9s-annotationlayer .a9s-annotation .BLUE .a9s-inner,
.a9s-annotationlayer .a9s-selection .BLUE .a9s-inner {
  stroke-width:2px !important;
  stroke:#00E0FF !important;
}

.a9s-annotation.editable.selected .BLUE {
  fill: rgba(0,224,255, 0.15) !important;
}

.a9s-annotation.editable .BLUE .a9s-inner {
  fill: rgba(0,224,255, 0.15) !important;
}

.a9s-annotation.editable.BLUE .a9s-inner:hover {
  fill: rgba(0,224,255, 0.07) !important;
}

.a9s-annotation.editable .BLUE .a9s-inner:hover {
  fill: rgba(0,224,255, 0.07) !important;
}

.a9s-annotation.editable.BLUE .a9s-inner {
  fill: rgba(0,224,255, 0.15) !important;
}

/*Pink colour*/
.toolbar-wrapper .tag-selector .pink.active button {
  color:#FA00FF;
  background-color: #FA00FF;
}

.a9s-annotationlayer .a9s-annotation.PINK .a9s-inner {
  stroke: #FA00FF !important;
}

.a9s-annotationlayer .a9s-annotation.PINK.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.PINK:hover .a9s-inner  {
  stroke:#FA00FF !important;
}

.a9s-annotationlayer .a9s-annotation .PINK .a9s-inner,
.a9s-annotationlayer .a9s-selection .PINK .a9s-inner {
  stroke-width:2px !important;
  stroke:#FA00FF !important;
}

.a9s-annotation.editable.selected .PINK {
  fill: rgba(250,0,255, 0.15) !important;
}

.a9s-annotation.editable .PINK .a9s-inner {
  fill: rgba(250,0,255, 0.15) !important;
}

.a9s-annotation.editable.PINK .a9s-inner:hover {
  fill: rgba(250,0,255, 0.07) !important;
}

.a9s-annotation.editable .PINK .a9s-inner:hover {
  fill: rgba(250,0,255, 0.07) !important;
}

.a9s-annotation.editable.PINK .a9s-inner {
  fill: rgba(250,0,255, 0.15) !important;
}

/*Purple colour*/
.toolbar-wrapper .tag-selector .purple.active button {
  color:#8F00FF;
  background-color: #8F00FF;
}

.a9s-annotationlayer .a9s-annotation.PURPLE .a9s-inner {
  stroke: #8F00FF !important;
}

.a9s-annotationlayer .a9s-annotation.PURPLE.editable.selected .a9s-inner,
.a9s-annotationlayer .a9s-annotation.PURPLE:hover .a9s-inner  {
  stroke:#8F00FF !important;
}

.a9s-annotationlayer .a9s-annotation .PURPLE .a9s-inner,
.a9s-annotationlayer .a9s-selection .PURPLE .a9s-inner {
  stroke-width:2px !important;
  stroke:#8F00FF !important;
}

.a9s-annotation.editable.selected .PURPLE {
  fill: rgba(143,0,255, 0.15) !important;
}

.a9s-annotation.editable .PURPLE .a9s-inner {
  fill: rgba(143,0,255, 0.15) !important;
}


.a9s-annotation.editable.PURPLE .a9s-inner:hover {
  fill: rgba(143,0,255, 0.07) !important;
}

.a9s-annotation.editable .PURPLE .a9s-inner:hover {
  fill: rgba(143,0,255, 0.07) !important;
}

.a9s-annotation.editable.PURPLE .a9s-inner {
  fill: rgba(143,0,255, 0.15) !important;
}

/*.a9s-annotationlayer:hover {*/
/*  cursor: crosshair;*/
/*}*/

/* #crosshair-h {*/
/*  width: 100%;*/
/*}*/

/*#crosshair-v {*/
/*  height: 100%;*/
/*}*/

/*.hair {*/
/*  cursor: grab;*/
/*  position: fixed;*/
/*  top:0; left:0;*/
/*  margin-top: -3px;*/
/*  margin-left: -2px;*/
/*  background: transparent;*/
/*  border-top: 2px solid #fff;*/
/*  border-left: 2px solid #fff;*/
/*  z-index: 1000;*/
/*  pointer-events: auto;*/
/*}*/